import InlineMessage from '@ingka/inline-message';
import information from '@ingka/ssr-icon/paths/information-circle';
import { useTranslation } from 'react-i18next';
import { FileUploadSuccess } from 'types/dataContext';

export const SuccessMessage = ({ fileName, fileUploadRespData }:{ fileName: string, fileUploadRespData: FileUploadSuccess }) => {
    const { t } = useTranslation();
    const { userAction, isUpdatedAt, isUpdatedBy } = fileUploadRespData;

    return (
        !userAction
            ? (
                <InlineMessage
                    data-testid="FileUpload-inline-message-informative"
                    ssrIcon={information}
                    title={t('UPLOAD_SUCCESSFUL')}
                    body={`${fileName} ${t('UPLOADED_AT')} ${isUpdatedAt} ${t('BY')} ${isUpdatedBy}`}
                    dismissable={false}
                    subtle
                />
            )
            : (
                <InlineMessage
                    data-testid="FileUpload-inline-message-success"
                    title={t('UPLOAD_SUCCESSFUL')}
                    body={`${fileName} ${t('UPLOADED_AT')} ${isUpdatedAt} ${t('BY')} ${isUpdatedBy}`}
                    variant="positive"
                    dismissable={false}
                />
            )
    );
};
