import React, { useMemo, useState } from 'react';
import { NewHiresDelta, RecruitmentNeedsDelta } from 'types/recruitmentNeeds';
import InlineMessage from '@ingka/inline-message';
import useData from 'hooks/useData';
import { useTranslation } from 'react-i18next';
import information from '@ingka/ssr-icon/paths/information';
import useApp from 'hooks/useApp';
import Text from '@ingka/text';
import { formatDate, isoWeekFromDateString, weekYearFromDateString } from 'utils/date';
import Button from '@ingka/button';
import calendar from '@ingka/ssr-icon/paths/calendar';
import Table, { TableHeader, TableBody } from '@ingka/table';
import { LoadingWrapper } from 'components/LoadingIndicator/LoadingWrapper';
import { getShortCC } from 'utils/text';
import arrowLeft from '@ingka/ssr-icon/paths/arrow-left-small';
import arrowRight from '@ingka/ssr-icon/paths/arrow-right-small';
import { TFunction } from 'i18next';
import { CheckBox } from 'types/appContext';
import classes from './NewHiresTable.module.scss';
import { getCCLabel, getFilteredNewHires, getWeeklyHires } from './RecruitmentNeedsFunctions';

interface NewHiresProps {
    isLoading: boolean;
    recruitmentNeeds: RecruitmentNeedsDelta[] | null;
}

interface NewHiresTableProps {
    t: TFunction<'translation', undefined>;
    isLoading: boolean;
    tableData: NewHiresDelta[];
    filteredCostCenterOMData: CheckBox[] | undefined;
}

const NewHiresTable: React.FC<NewHiresTableProps> = ({ t, isLoading, tableData, filteredCostCenterOMData }) => (
    <LoadingWrapper isLoading={isLoading}>
        <Table id="new-hires-table" data-testid="new-hires-table" fullWidth className={isLoading ? classes.table__loading : ''}>
            <TableHeader id="new-hires-table-header" data-testid="new-hires-table-header">
                <tr>
                    <th className={classes.table__header}>
                        <Text tagName="h5" headingSize="xs">{t('COST_CENTRE')}</Text>
                    </th>
                    <th className={classes.table__header}>
                        <Text tagName="h5" headingSize="xs">{t('CONTRACT_DETAILS')}</Text>
                    </th>
                    <th className={classes.table__header}>
                        <Text tagName="h5" headingSize="xs">{t('CONTRACT_PERIOD')}</Text>
                    </th>
                    <th className={classes.table__header}>
                        <Text tagName="h5" headingSize="xs">{t('NOTES')}</Text>
                    </th>
                </tr>
            </TableHeader>
            <TableBody id="new-hires-table-body" data-testid="new-hires-table-body">
                {tableData?.map(({ costCentre, contractHours, employmentType, contractType, startDate, endDate, notes }, index) => (
                    <tr key={`new-hires-table-${index + 0}`}>
                        <td key={`new-hires-table-cc-${index + 1}`} className={classes['new-hires-costCenter']}>
                            {`${getShortCC(costCentre)} ${t(getCCLabel(costCentre, filteredCostCenterOMData)).toLowerCase()}`}
                        </td>
                        <td key={`new-hires-table-hour-${index + 1}`}>
                            {`${t(contractType)}, ${t(employmentType)}, ${contractHours} ${t('HRS_PER_WEEK')}`}
                        </td>
                        <td key={`new-hires-table-period-${index + 1}`}>{`${formatDate(startDate)} - ${formatDate(endDate)}`}</td>
                        <td key={`new-hires-table-notes-${index + 1}`}>{notes}</td>
                    </tr>
                ))}
            </TableBody>
        </Table>
    </LoadingWrapper>
);

const NewHires: React.FC<NewHiresProps> = ({ isLoading, recruitmentNeeds }) => {
    const { t } = useTranslation();
    const { currentScenario } = useData();
    const { filteredCostCenterOMData, timeSelection } = useApp();
    const startWeek = weekYearFromDateString(timeSelection.startDate);
    const endWeek = weekYearFromDateString(timeSelection.endDate);
    const [showPlanningPeriod, setShowPlanningPeriod] = useState<boolean>(true);
    const [weeklyOverviewWeekCount, setWeeklyOverviewWeekCount] = useState<number>(0);
    const [availableWeeksList, setAvailableWeeksList] = useState<string[] | null>(null);

    const newHiresData = useMemo(() => {
        setShowPlanningPeriod(true);
        setWeeklyOverviewWeekCount(0);
        if (recruitmentNeeds?.length) {
            const filteredNewHires = getFilteredNewHires(filteredCostCenterOMData, recruitmentNeeds, timeSelection);
            setAvailableWeeksList(getWeeklyHires(filteredNewHires ?? []).sort((a, b) => a.localeCompare(b)));

            return filteredNewHires;
        }

        return null;
    }, [recruitmentNeeds, filteredCostCenterOMData, timeSelection]);

    const weeklyNewHires = useMemo(() => {
        if (recruitmentNeeds?.length && availableWeeksList?.length && !showPlanningPeriod) {
            return (
                getFilteredNewHires(filteredCostCenterOMData, recruitmentNeeds, timeSelection)?.filter(
                    ({ startDate }) => isoWeekFromDateString(startDate) === availableWeeksList?.[weeklyOverviewWeekCount]
                ) ?? null
            );
        }

        return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableWeeksList, weeklyOverviewWeekCount, showPlanningPeriod]);

    const hasNewHireData: boolean = showPlanningPeriod ? !newHiresData?.length : !weeklyNewHires?.length;
    const newHiresHeader: string = `${t('PLANNED_RECRUITMENT_HEADER')}: `;
    const planningPeriodRange: string = `${t('WEEK')} ${startWeek} - ${t('WEEK')} ${endWeek}`;
    const availableWeek = availableWeeksList?.length ? availableWeeksList?.[weeklyOverviewWeekCount]?.split('-') : '';
    const weeklyRange: string = availableWeek?.length ? `${availableWeek?.[1]} - ${availableWeek?.[0]}` : `${t('WEEK')} ${startWeek}`;

    return (
        <>
            <div className={classes['new-hires__container']}>
                <div className={classes['new-hires__header']}>
                    <div>
                        <Text tagName="h5" headingSize="xs" id="new-hires-header" data-testid="new-hires-header">
                            {newHiresHeader}
                            {(showPlanningPeriod || !currentScenario) ? planningPeriodRange : weeklyRange}
                        </Text>
                    </div>
                    <div>
                        {(!showPlanningPeriod && currentScenario) && (
                            <>
                                <Button
                                    id="new-hires-week-left-btn"
                                    data-testid="new-hires-week-left-btn"
                                    className={classes['new-hires__week-switch-btn']}
                                    type="secondary"
                                    iconOnly
                                    size="xsmall"
                                    ssrIcon={arrowLeft}
                                    disabled={weeklyOverviewWeekCount === 0}
                                    onClick={() => {
                                        if (weeklyOverviewWeekCount === 0) {
                                            return;
                                        }
                                        setWeeklyOverviewWeekCount(prevCount => prevCount - 1);
                                    }}
                                />
                                <Button
                                    id="new-hires-week-right-btn"
                                    data-testid="new-hires-week-right-btn"
                                    className={classes['new-hires__week-switch-btn']}
                                    type="secondary"
                                    iconOnly
                                    size="xsmall"
                                    ssrIcon={arrowRight}
                                    disabled={weeklyOverviewWeekCount === ((availableWeeksList?.length ?? 0) - 1)}
                                    onClick={() => {
                                        if (weeklyOverviewWeekCount === ((availableWeeksList?.length ?? 0) - 1)) {
                                            return;
                                        }
                                        setWeeklyOverviewWeekCount(prevCount => prevCount + 1);
                                    }}
                                />
                            </>
                        )}
                    </div>
                </div>
                <div>
                    <Button
                        id="new-hires-table-switch-btn"
                        data-testid="new-hires-table-switch-btn"
                        size="small"
                        type="tertiary"
                        ssrIcon={calendar}
                        disabled={!currentScenario || hasNewHireData}
                        text={showPlanningPeriod ? t('SWITCH_WEEKLY_OVERVIEW') : t('SWITCH_PLANNING_PERIOD_OVERVIEW')}
                        onClick={() => setShowPlanningPeriod(!showPlanningPeriod)}
                    />
                </div>
            </div>

            {(!isLoading && hasNewHireData) || !currentScenario ? (
                <InlineMessage
                    id="new-hires-inline-message"
                    data-testid="new-hires-inline-message"
                    ssrIcon={information}
                    title={!currentScenario ? t('COWORKERS_SELECT_SCENARIO') : t('NO_DATA_MESSAGE')}
                />
            ) : (
                <div>
                    {showPlanningPeriod
                        ? <NewHiresTable t={t} isLoading={isLoading} tableData={newHiresData ?? []} filteredCostCenterOMData={filteredCostCenterOMData} />
                        : <NewHiresTable t={t} isLoading={isLoading} tableData={weeklyNewHires ?? []} filteredCostCenterOMData={filteredCostCenterOMData} />}
                </div>
            )}
        </>
    );
};

export default React.memo(NewHires);
