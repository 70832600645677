import { ContractDelta,
    Scenario,
    CoworkerDetailsEditForm,
    ScenarioDeltaType,
    TypeContract,
    CostDistributionScenarioDelta,
    ChangeType,
    DummyCoworker } from 'types/scenario';
import { Coworker } from 'types/coworker';
import { CountryOMData } from 'types/dataContext';
import { DATE_FORMAT_STANDARD } from 'utils/date';
import moment from 'moment';
import { FieldNamesMarkedBoolean, UseFieldArrayReplace, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { calculateNumberDividedToPercentage } from 'utils/number';
import { getCostCentreDescription } from 'hooks/useGrid/gridFunctions';
import { ModulationRange } from 'types/modulation';
import { t } from 'i18next';
import { getShortCC } from 'utils/text';
import { getContractRangeMax } from 'utils/contractRange';
import { isPermanent } from 'utils/coworkers';

export const convertDeltaToISODates = (formState: ContractDelta) => ({
    startDate: moment(formState.startDate).startOf('isoWeek').format(DATE_FORMAT_STANDARD),
    endDate: formState.endDate ? moment(formState.endDate).endOf('isoWeek').format(DATE_FORMAT_STANDARD) : formState.endDate,
});

export const getCoworkerCostCentres = (
    coworker: Coworker | DummyCoworker,
    unfilteredCountryOMData: CountryOMData | undefined
) => coworker.costDistributions.map(cd => ({
    costCentre: cd.costCentre,
    displayName: `${getShortCC(cd.costCentre)} ${getCostCentreDescription(cd.costCentre, unfilteredCountryOMData)}`,
    sourceIsPa: !coworker.isDummy,
}));

export const getCoworkerDetailsInitialState = (
    coworker: Coworker | DummyCoworker,
): CoworkerDetailsEditForm => (coworker.contractRange ? {
    startDate: '',
    endDate: null,
    type: isPermanent(coworker) ? 'PERMANENT' : 'TEMPORARY',
    costDistributions: coworker.costDistributions ?? [],
    hoursPerWeekRange: coworker.contractRange,
    hoursPerWeek: null,
    timeStamp: new Date().toISOString()
} : {
    startDate: '',
    endDate: null,
    type: isPermanent(coworker) ? 'PERMANENT' : 'TEMPORARY',
    costDistributions: coworker.costDistributions ?? [],
    hoursPerWeekRange: null,
    hoursPerWeek: coworker.hoursPerWeek ?? 0,
    timeStamp: new Date().toISOString()
});

export const updateCoworkerDetailsFormState = (
    coworker: Coworker | DummyCoworker,
    scenario: Scenario | undefined,
    defaultValues: CoworkerDetailsEditForm,
    getValues: UseFormGetValues<ContractDelta>,
    setValue: UseFormSetValue<ContractDelta>,
    replace: UseFieldArrayReplace<ContractDelta, 'costDistributions'>
) => {
    if (!getValues('startDate')) {
        return;
    }

    const startDate = moment(getValues('startDate')).format(DATE_FORMAT_STANDARD);
    const endDate = getValues('endDate') ? moment(getValues('endDate')).endOf('isoWeek').format(DATE_FORMAT_STANDARD) : null;
    const contractDeltas = scenario?.scenarioData.find(data => data.personId === coworker.personId)?.contractDeltas ?? [];
    const timeRangeContractDelta = contractDeltas.find(delta => delta.startDate === startDate && delta.endDate === endDate);

    if (!timeRangeContractDelta) {
        return;
    }

    setValue('type', timeRangeContractDelta.type ?? getValues('type'));
    if (timeRangeContractDelta.hoursPerWeek) {
        setValue('hoursPerWeek', timeRangeContractDelta.hoursPerWeek ?? getValues('hoursPerWeek'));
    }

    if (timeRangeContractDelta.hoursPerWeekRange) {
        setValue('hoursPerWeekRange', timeRangeContractDelta.hoursPerWeekRange ?? getValues('hoursPerWeekRange'));
    }

    if (timeRangeContractDelta.costDistributions?.length) {
        replace(timeRangeContractDelta.costDistributions);
    }
};

export const getCoworkerDelta = (
    touchedFields: {
        type?: boolean | undefined;
        hoursPerWeek?: boolean | undefined;
        hoursPerWeekRange?: { type?: boolean | undefined; range?: {
            max?: boolean | undefined; min?: boolean | undefined;
        } | undefined; description?: boolean | undefined; } | boolean | undefined;
        startDate?: boolean | undefined;
        endDate?: boolean | undefined;
        costDistributions?: {
            costCentre?: boolean | undefined;
            costCentrePercent?: boolean | undefined;
        }[] | undefined;
    },
    formState: ContractDelta,
    initialState: ContractDelta,
    coworker: Coworker | DummyCoworker
) => {
    const uniqueChanges = {} as ContractDelta;
    const initialCCDistributions = initialState.costDistributions;
    (Object.keys(touchedFields) as (keyof typeof touchedFields)[]).forEach(key => {
        if (touchedFields?.[key]) {
            if (key === 'costDistributions') {
                const { costDistributions } = formState;
                uniqueChanges.costDistributions = [];
                touchedFields[key]?.forEach((el, index) => {
                    if (costDistributions?.length && (el.costCentre || el.costCentrePercent)) {
                        uniqueChanges.costDistributions?.push(costDistributions[index]);
                    }
                });

                return;
            }

            // @ts-ignore TODO: Fix type
            uniqueChanges[key] = formState[key];
        }
    });

    const scenarioDeltas: ScenarioDeltaType[] = [];
    if (uniqueChanges.hoursPerWeek && formState.hoursPerWeek && coworker.hoursPerWeek !== formState?.hoursPerWeek
    ) {
        scenarioDeltas.push({
            type: ChangeType.CONTRACT_HOURS,
            from: coworker.hoursPerWeek,
            to: formState.hoursPerWeek
        });
    }
    const isHoursPerWeekRangeChanged : boolean = (touchedFields.hoursPerWeekRange !== null
    && (coworker?.contractRange?.type !== formState?.hoursPerWeekRange?.type));

    // Note: removed uniqueChanges constraint for hoursPerWeekRange, due to bug in scenario data FE gets back. Will be resolved in CAP-2113
    if (isHoursPerWeekRangeChanged && coworker.contractRange && formState.hoursPerWeekRange) {
        scenarioDeltas.push({
            type: ChangeType.CONTRACT_HOURS,
            from: coworker.contractRange,
            to: formState.hoursPerWeekRange
        });
    }

    if (uniqueChanges.type) {
        const isHoursPerWeekChanged : boolean = (uniqueChanges.hoursPerWeek !== null && formState.hoursPerWeek !== null
            && coworker.hoursPerWeek !== formState?.hoursPerWeek);
        const isContractDatesOnlyChanged = (
            !(isHoursPerWeekChanged || isHoursPerWeekRangeChanged || touchedFields.costDistributions)
            && touchedFields.startDate && touchedFields.endDate);

        if (isContractDatesOnlyChanged
            && (coworker.contractType === TypeContract[uniqueChanges.type] || coworker.contractType === uniqueChanges.type)) {
            scenarioDeltas.push({
                type: ChangeType.CONTRACT_DATES,
                from: 'TEMPORARY',
                to: 'TEMPORARY',
                hoursPerWeek: coworker.contractRange?.range.max ?? coworker.hoursPerWeek,
            });
        } else {
            const hours = formState.hoursPerWeekRange ? getContractRangeMax(formState.hoursPerWeekRange) : formState.hoursPerWeek;
            scenarioDeltas.push({
                type: ChangeType.CONTRACT_TYPE,
                from: isPermanent(coworker) ? 'PERMANENT' : 'TEMPORARY',
                to: formState.type,
                hoursPerWeek: hours,
            });
        }
    }

    if (uniqueChanges.costDistributions?.length) {
        const hoursForm = formState.hoursPerWeekRange ? getContractRangeMax(formState.hoursPerWeekRange) : formState.hoursPerWeek;
        const hoursInitial = initialState.hoursPerWeekRange ? getContractRangeMax(initialState.hoursPerWeekRange) : initialState.hoursPerWeek;
        const weeklyHours = hoursForm ?? hoursInitial;
        if (formState.costDistributions[0].costCentre !== initialState.costDistributions[0].costCentre) {
            scenarioDeltas.push(
                {
                    type: ChangeType.COST_DISTRIBUTION,
                    from: {
                        costCentre: initialCCDistributions[0].costCentre,
                        percentage: initialCCDistributions[0].costCentrePercent,
                        hours: calculateNumberDividedToPercentage(
                            weeklyHours,
                            initialCCDistributions[0].costCentrePercent
                        )
                    },
                    to: {
                        costCentre: initialCCDistributions[0].costCentre,
                        percentage: 0,
                        hours: calculateNumberDividedToPercentage(weeklyHours, 0)
                    },
                }

            );
        }
        scenarioDeltas.push(
            ...formState.costDistributions.map((ccd, index) => ({
                type: ChangeType.COST_DISTRIBUTION,
                from: {
                    costCentre: index > initialCCDistributions.length - 1
                        ? initialCCDistributions[0].costCentre : initialCCDistributions[index].costCentre,
                    percentage: index > initialCCDistributions.length - 1
                        ? initialCCDistributions[0].costCentrePercent : initialCCDistributions[index].costCentrePercent,
                    hours: calculateNumberDividedToPercentage(
                        weeklyHours,
                        index > initialCCDistributions.length - 1
                            ? initialCCDistributions[0].costCentrePercent : initialCCDistributions[index].costCentrePercent,
                    )
                },
                to: {
                    costCentre: ccd.costCentre,
                    percentage: ccd.costCentrePercent,
                    hours: calculateNumberDividedToPercentage(
                        weeklyHours,
                        ccd.costCentrePercent
                    )
                },
            } as CostDistributionScenarioDelta))
        );
    }

    return scenarioDeltas;
};

export const getContractHoursRange = (coworker: Coworker | DummyCoworker) => (coworker.contractRange?.range.min !== null
    ? `${coworker.contractRange?.range.min ?? 0} - ${coworker.contractRange?.range.max ?? 0}`
    : `${coworker.contractRange?.range.max ?? 0}`);

export const formatTime = (time: { hours: number, minutes: number }) => `${time.hours}${t('HOURS_ABBREVIATED')} ${time.minutes}${t('MINUTES_ABBREVIATED')}`;

export const getCoworkerModulation = (range: ModulationRange | undefined) => (range
    ? `${t('YES_SHORT')}, ${formatTime(range.minLimit)} - ${formatTime(range.maxLimit)}`
    : t('NO'));
/**
 * Returns whether all required fields for enabling the save button are touched
 * @param touchedFields
 * @returns boolean
 */
export const areRequiredFieldsTouched = (
    touchedFields: Partial<Readonly<FieldNamesMarkedBoolean<CoworkerDetailsEditForm>>>
): boolean => !!touchedFields.hoursPerWeek
    || !!touchedFields.hoursPerWeekRange
    || !!touchedFields.type
    || !!touchedFields.costDistributions;
